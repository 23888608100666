import * as React from 'react';

export function Grid({
  children,
  wrapperClassName,
  gridClassName,
}: {
  children: React.ReactNode;
  gridClassName?: string;
  wrapperClassName?: string;
}) {
  return (
    <div className={`w-100 ${wrapperClassName}`}>
      <div className={`w-100 ${gridClassName}`}>{children}</div>
    </div>
  );
}
