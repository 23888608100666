import { fetchApiHub } from '../../lib/fetch-api-hub';

export const sendContactEmail = async (data: {
  from: string;
  to: string;
  subject?: string;
  text?: string;
  html?: string;
  contextMessage?: string;
}): Promise<void> => {
  const payload = {
    data,
  };
  const res = await fetchApiHub('/action/email/sendContactEmail', { method: 'POST' }, payload);
  return res;
};
