import { fetchApiHub } from 'frontastic';

interface CreateOrderResponse {
  accessToken: string;
  orderId: string;
}

export const createOrder = async (): Promise<CreateOrderResponse> => {
  const payload = {};
  const res = await fetchApiHub(
    '/action/paypal/createOrder',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  return res;
};

export const capturePayment = async (): Promise<any> => {
  const payload = {};
  const res = await fetchApiHub(
    '/action/paypal/capturePayment',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  return res;
};
