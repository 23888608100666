import { UseAccount } from './UseAccount';
import { UseAdyen } from './UseAdyen';
import { UseCart } from './UseCart';
import { UseCategories } from './UseCategories';
import { UseCategory } from './UseCategory';
import { UseEmail } from './UseEmail';
import { UseSearch } from './UseSearch';
import { UseSession } from './UseSession';
import { UseWishlist } from './UseWishlist';
import {
  getAccount,
  changePassword,
  confirm,
  requestConfirmationEmail,
  login,
  logout,
  register,
  requestPasswordReset,
  resetPassword,
  update,
  addAddress,
  updateAddress,
  updateAddressTypeAssociation,
  removeAddress,
  setDefaultBillingAddress,
  setDefaultShippingAddress,
  getStaticCompanyByVatId,
  getCompanyByVatId,
} from '../../actions/account';
import { createSession } from '../../actions/adyen';
import {
  cartItems,
  addItem,
  checkout,
  orderHistory,
  removeItem,
  getShippingMethods,
  setPartialDelivery,
  setShippingMethod,
  updateCart,
  updateItem,
  redeemDiscountCode,
  removeDiscountCode,
  getProjectSettings,
  addPaymentByMethod,
  setLastOrderId,
  getLastOrderId,
  removeLastOrderId,
  getOrderById,
} from '../../actions/cart';
import { getCategories } from '../../actions/categories';
import {
  getLastCategoryAncestors,
  getLastCategoryKey,
  removeLastCategoryKey,
  setLastCategoryKey,
} from '../../actions/category';
import { sendContactEmail } from '../../actions/email';
import { createOrder, capturePayment } from '../../actions/paypal';
import { trackEvent } from '../../actions/search';
import { getMarketingPopupTimestamp, getSessionData, setMarketingPopupTimestamp } from '../../actions/session';
import { getWishlist, addToWishlist, removeLineItem, updateLineItem } from '../../actions/wishlist';

export interface FrontasticState {
  useCart: UseCart;
  useAccount: UseAccount;
  useWishlist: UseWishlist;
  useAdyen: UseAdyen;
  useEmail: UseEmail;
  usePaypal: {
    createOrder: typeof createOrder;
    capturePayment: typeof capturePayment;
  };
  useCategory: UseCategory;
  useCategories: UseCategories;
  useSearch: UseSearch;
  useSession: UseSession;
}

export const getFrontasticState = (): FrontasticState => {
  return {
    useCart: {
      ...cartItems(),
      addItem,
      updateCart,
      removeItem,
      updateItem,
      getShippingMethods,
      setPartialDelivery,
      setShippingMethod,
      checkout,
      orderHistory,
      getOrderById,
      getProjectSettings,
      redeemDiscountCode,
      removeDiscountCode,
      addPaymentByMethod,
      setLastOrderId,
      getLastOrderId,
      removeLastOrderId,
    },
    useAccount: {
      ...getAccount(),
      login,
      logout,
      register,
      confirm,
      requestConfirmationEmail,
      changePassword,
      requestPasswordReset,
      resetPassword,
      update,
      addAddress,
      updateAddress,
      updateAddressTypeAssociation,
      removeAddress,
      setDefaultBillingAddress,
      setDefaultShippingAddress,
      getStaticCompanyByVatId,
      getCompanyByVatId,
    },
    useWishlist: {
      ...getWishlist(),
      addToWishlist,
      removeLineItem,
      updateLineItem,
    },
    useAdyen: {
      createSession,
    },
    useEmail: {
      sendContactEmail,
    },
    usePaypal: {
      createOrder,
      capturePayment,
    },
    useCategory: {
      ...getLastCategoryAncestors(),
      setLastCategoryKey,
      getLastCategoryKey,
      removeLastCategoryKey,
    },
    useCategories: {
      ...getCategories(),
    },
    useSearch: {
      trackEvent,
    },
    useSession: {
      ...getSessionData(),
      setMarketingPopupTimestamp,
      getMarketingPopupTimestamp,
    },
  };
};
