import React, { createContext, useCallback, useContext, useState } from 'react';
import { COMPARE_SKUS } from '../../../helpers/constants/sessionStorage';

export interface UseComparison {
  data: string[] | null;
  getComparisonSkus: () => string[] | null;
  addComparisonSku: (sku: string) => void;
  removeComparisonSku: (sku: string) => void;
}

const initialState: UseComparison = {
  data: null,
  getComparisonSkus: () => null,
  addComparisonSku: (sku: string) => undefined,
  removeComparisonSku: (sku: string) => undefined,
};

export const ComparisonContext = createContext(initialState);

const ComparisonProvider: React.FC = ({ children }) => {
  const [comparisonSkus, setComparisonSkus] = useState<string[] | null>(null);

  const addComparisonSku = useCallback((sku) => {
    const skus = getComparisonSkus();
    const newArray = skus ? [...skus, sku] : [sku];
    // https://developer.school/snippets/react/localstorage-is-not-defined-nextjs#how-to-fix-this-issue
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem(COMPARE_SKUS, JSON.stringify(newArray));
    }
    setComparisonSkus(getComparisonSkus());
  }, []);

  const removeComparisonSku = useCallback((sku) => {
    const skus = getComparisonSkus();
    const newArray: string[] | null = skus ? skus.filter((value) => value !== sku) : null;
    // https://developer.school/snippets/react/localstorage-is-not-defined-nextjs#how-to-fix-this-issue
    if (typeof window !== 'undefined') {
      if (newArray && newArray.length > 0) {
        window.sessionStorage.setItem(COMPARE_SKUS, JSON.stringify(newArray));
      } else {
        window.sessionStorage.removeItem(COMPARE_SKUS);
      }
    }
    setComparisonSkus(getComparisonSkus());
  }, []);

  const getComparisonSkus = useCallback(() => {
    let skus: string[] | null = null;
    // https://developer.school/snippets/react/localstorage-is-not-defined-nextjs#how-to-fix-this-issue
    if (typeof window !== 'undefined') {
      const item = window.sessionStorage.getItem(COMPARE_SKUS);
      skus = item ? JSON.parse(item) : null;
    }
    return skus;
  }, []);

  const value = {
    data: comparisonSkus,
    addComparisonSku: addComparisonSku,
    removeComparisonSku: removeComparisonSku,
    getComparisonSkus: getComparisonSkus,
  };

  return <ComparisonContext.Provider value={value}>{children}</ComparisonContext.Provider>;
};

export default ComparisonProvider;

export const useComparison = () => {
  return useContext(ComparisonContext);
};
