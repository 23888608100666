import useSWR from 'swr';
import { revalidateOptions } from 'frontastic';
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';
import { MARKETING_POPUP_TIMESTAMP } from '../../../helpers/constants/sessionStorage';

export const getSessionData = () => {
  return useSWR('/action/account/getSessionData', fetchApiHub, revalidateOptions);
};

export const setMarketingPopupTimestamp = () => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(MARKETING_POPUP_TIMESTAMP, new Date().toISOString());
  }
};

export const getMarketingPopupTimestamp = () => {
  return typeof window !== 'undefined' ? window.sessionStorage.getItem(MARKETING_POPUP_TIMESTAMP) : null;
};
