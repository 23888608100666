import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export function Cell({
  size,
  className,
  children,
}: {
  size: number | 'viewport';
  className?: string;
  children: React.ReactNode;
}) {
  const containerClasses = size === 'viewport' ? 'container-fluid py-0' : '';
  const colClasses = size === 'viewport' ? 'py-0' : `col-${size}`;
  return (
    <Container className={containerClasses}>
      <Row className="justify-content-center">
        <Col className={colClasses}>{children}</Col>
      </Row>
    </Container>
  );
}
