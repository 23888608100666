import { Address } from '@Types/account/Address';
import { Cart } from '@Types/cart/Cart';
import { Discount } from '@Types/cart/Discount';
import { Order } from '@Types/cart/Order';
import { Variant } from '@Types/product/Variant';
import useSWR, { mutate } from 'swr';
import { fetchApiHub, revalidateOptions } from 'frontastic';
import { ORDER_ID } from '../../../helpers/constants/sessionStorage';

export type CartDetails = {
  account?: { email: string };
  shipping?: Address;
  billing?: Address;
  comment?: string;
  vatId?: string;
};

export const cartItems = () => {
  return useSWR('/action/cart/getCart', fetchApiHub, revalidateOptions);
};

export const checkout = async (): Promise<Order> => {
  const res = await fetchApiHub('/action/cart/checkout', {
    method: 'POST',
  });
  await mutate('/action/cart/getCart', res);
  return res;
};

export const orderHistory = async () => {
  return await fetchApiHub('/action/cart/getOrders');
};

export const getOrderById = async (orderId: string) => {
  const payload = {
    orderId,
  };
  return await fetchApiHub('/action/cart/getOrder', { method: 'POST' }, payload);
};

export const getProjectSettings = async () => {
  return await fetchApiHub('/action/project/getProjectSettings');
};

export const getShippingMethods = async () => {
  return await fetchApiHub('/action/cart/getShippingMethods');
};

export const addItem = async (variant: Variant, quantity: number) => {
  const payload = {
    variant: {
      sku: variant.sku,
      count: quantity,
      shippingWeight: parseFloat(variant.attributes['ecom_weight_total_shipping'] ?? 0),
    },
  };
  const res = await fetchApiHub(
    '/action/cart/addToCart',
    {
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const removeItem = async (lineItemId: string) => {
  const payload = {
    lineItem: { id: lineItemId },
  };

  const res = await fetchApiHub(
    '/action/cart/removeLineItem',
    {
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const updateItem = async (lineItemId: string, newQuantity: number) => {
  const payload = {
    lineItem: {
      id: lineItemId,
      count: newQuantity,
    },
  };
  const res = await fetchApiHub(
    '/action/cart/updateLineItem',
    {
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const updateCart = async (payload: CartDetails): Promise<Cart> => {
  const res = await fetchApiHub(
    '/action/cart/updateCart',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const setShippingMethod = async (shippingMethodId: string) => {
  const payload = {
    shippingMethod: {
      id: shippingMethodId,
    },
  };

  const res = await fetchApiHub(
    `/action/cart/setShippingMethod?shippingMethodId=${shippingMethodId}`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const setPartialDelivery = async (partialDelivery = false) => {
  const payload = {
    partialDelivery: partialDelivery,
  };

  const res = await fetchApiHub(
    `/action/cart/setPartialDelivery?partialDelivery=${partialDelivery}`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const redeemDiscountCode = async (code: string) => {
  const payload = {
    code: code,
  };
  const res = await fetchApiHub(
    `/action/cart/redeemDiscount`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  if (typeof res === 'string') {
    // NOTE: Errors are returned as string with error message
    return res;
  } else {
    // NOTE: Success returned as cart object
    mutate('/action/cart/getCart', res, { revalidate: false });
  }
};

export const removeDiscountCode = async (discount: Discount) => {
  const payload = {
    discountId: discount.discountId,
  };
  const res = await fetchApiHub(
    '/action/cart/removeDiscount',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const addPaymentByMethod = async (method: string) => {
  const payload = {
    method: method,
  };
  const res = await fetchApiHub(
    `/action/cart/addPaymentByMethod`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const setLastOrderId = (orderId: string) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(ORDER_ID, orderId);
  }
};

export const getLastOrderId = () => {
  return typeof window !== 'undefined' ? window.sessionStorage.getItem(ORDER_ID) : null;
};

export const removeLastOrderId = () => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.removeItem(ORDER_ID);
  }
};
