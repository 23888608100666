import useSWR, { mutate } from 'swr';
import { CATEGORY_KEY } from '../../../helpers/constants/sessionStorage';
import { revalidateOptions } from '../../index';
import { fetchApiHub } from '../../lib/fetch-api-hub';

export const getLastCategoryAncestors = () => {
  const lastCategoryKey = getLastCategoryKey();
  return useSWR(
    lastCategoryKey
      ? '/action/category/queryCategoryAncestors?lastCategoryKey=' + encodeURIComponent(lastCategoryKey)
      : '/action/category/queryCategoryAncestors',
    fetchApiHub,
    revalidateOptions,
  );
};

export const setLastCategoryKey = (key) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(CATEGORY_KEY, key);
    mutate('/action/category/queryCategoryAncestors');
  }
};

export const getLastCategoryKey = () => {
  return typeof window !== 'undefined' ? window.sessionStorage.getItem(CATEGORY_KEY) : null;
};

export const removeLastCategoryKey = () => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.removeItem(CATEGORY_KEY);
    mutate('/action/category/queryCategoryAncestors');
  }
};
