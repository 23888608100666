import { fetchApiHub } from '../../lib/fetch-api-hub';
import { TrackEventData, TrackEventKey } from '../../provider/frontastic/UseSearch';

export const trackEvent = async (event: TrackEventKey, data: Array<TrackEventData>): Promise<any> => {
  if (!event || !Array.isArray(data) || data.length === 0) {
    return;
  }

  const payload = {
    event: event,
    eventData: [],
  };

  data.forEach((item) => {
    if (!item.id) {
      return;
    }
    payload.eventData.push({
      id: item.id,
      title: item.title || undefined,
      query: item.query || undefined,
      pos: item.pos ?? undefined,
      page: item.page ?? undefined,
      pageSize: item.pageSize ?? undefined,
      count: item.count ?? undefined,
      price: item.price ?? undefined,
    });
  });

  if (payload.eventData.length === 0) {
    return;
  }

  return await fetchApiHub(
    '/action/search/trackEvent',
    {
      method: 'POST',
    },
    payload,
  );
};
